import { reportScriptLoadingError } from '../domain/scriptLoadingError';
export async function lazyLoadRecorder(importRecorderImpl = importRecorder) {
  try {
    return await importRecorderImpl();
  } catch (error) {
    reportScriptLoadingError({
      error,
      source: 'Recorder',
      scriptType: 'module'
    });
  }
}
async function importRecorder() {
  const module = await import(/* webpackChunkName: "recorder" */'./startRecording');
  return module.startRecording;
}
