import { inject, Injectable, signal } from '@angular/core';
import { filter, first, Observable, of, ReplaySubject, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthToken } from '../../interfaces/auth-token.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { functionalPermissionsMap, IFunctionalPermissions } from './../../../core/constants/functional-permissions-map';
import { userPermissionsMap } from '../../constants/user-Permissions.map';
import { DatadogService } from '../datadog.service';
import { IStaff } from '../../interfaces/staff.interface';
import { SettingsService } from '@pages/charging-settings/services/settings.service';
import { UserPreferencesService } from '../user-preferences.service';
import { SharedService } from '@shared/services/shared.service';
import { KeycloakTokenParsed } from 'keycloak-js';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';

export interface IUserPermission {
  bitPosition: number;
  description: string;
  externalUserPermissionReference: string;
  name: string;
}

export interface IUserAuthorizationLevel {
  roles?: Array<any>;
  userPermissions?: string[];
  functionalPermissions?: string[];
  email?: string;
  external_staff_reference: string;
  external_customer_reference: string;
  initialized: boolean;
}

export interface IUserPreferences {
  equipmentIdentification: string;
  equipmentUnit: string;
  gasUnit: string;
}

export enum EquipmentUnitPreference {
  METRIC = 'Metric',
  IMPERIAL = 'Imperial',
}
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private httpClient = inject(HttpClient);
  private dataDogService = inject(DatadogService);
  sharedService = inject(SharedService);
  private launchDarklyService = inject(LaunchDarklyService);
  private settingsService = inject(SettingsService);
  private userPreferencesService = inject(UserPreferencesService);
  equipmentIdentification = new ReplaySubject<string>(1);
  equipmentIdentification$ = this.equipmentIdentification.asObservable();

  vehicleIdentifier = signal<string>('');

  // does this needs to be reactive primitive? todo
  readonly userData = signal<KeycloakTokenParsed | null>(null);

  private _userDataStaff = new ReplaySubject<IStaff>(1);
  currentUserStaff$ = this._userDataStaff.asObservable();
  jwtHelper: JwtHelperService;

  private _userAuthorizationLevel = new ReplaySubject<IUserAuthorizationLevel>();
  userAuthorizationLevel$ = this._userAuthorizationLevel
    .asObservable()
    .pipe(filter((res: IUserAuthorizationLevel) => res.initialized == true));
  // store permissions in this service?

  isCustomerOnboarded: boolean | null = null;
  isCustomerOnboardedCallmade: boolean = false;
  private onboardingRequestPromise: Promise<boolean> | null = null;

  constructor() {
    this.jwtHelper = new JwtHelperService();
  }

  getOnboardedStatus() {
    const CustomerOnboardedUrl = `${environment.publicCharging}onboarding/CustomerOnboarded`;
    return this.httpClient.get<boolean>(CustomerOnboardedUrl);
  }

  getCustomerOnboardedStatus(): Observable<boolean> {
    if (this.isCustomerOnboarded !== null) {
      return of(this.isCustomerOnboarded);
    }

    return this.getOnboardedStatus().pipe(
      first(),
      tap((res) => {
        this.isCustomerOnboarded = res;
      }),
    );
  }

  setUserDataAfterTokenParsed() {}

  getStaffInfo(token: KeycloakTokenParsed): Observable<IStaff> {
    this.userData.set(token);

    return this.settingsService.getStaffByReference(token['customer']?.external_staff_reference).pipe(
      tap((staff: IStaff) => {
        //
        let userAuthLevel = this.createUserAuthLevel(staff, token);

        this._userAuthorizationLevel.next(userAuthLevel);
        this.dataDogService.logUserInfo(token, userAuthLevel, staff);
        this.settingsService.externalStaffReference = staff.externalStaffReference;

        this.userPreferencesService.usersPreferredVehicleIdentifier.set(staff.equipmentIdentification);

        this._userDataStaff.next(staff);
        this.launchDarklyService.initLaunchDarkly(staff);
        this.equipmentIdentification.next(staff.equipmentIdentification);
        this.vehicleIdentifier.set(staff.equipmentIdentification);
        this.sharedService.languageCode.set(staff.languageCode);
      }),
    );
  }

  createUserAuthLevel(staff: IStaff, decodedToken: any): IUserAuthorizationLevel {
    let createUserAUthLevel: IUserAuthorizationLevel = {
      roles: staff.roles,
      userPermissions: this.mapUserPermissions(decodedToken),
      functionalPermissions: this.mapFunctionalPermissions(decodedToken),
      email: decodedToken.email,
      external_staff_reference: decodedToken.customer?.external_staff_reference,
      external_customer_reference: decodedToken.customer?.external_customer_reference,
      initialized: true,
    };

    return createUserAUthLevel;
  }

  mapUserPermissions(decodedToken: any) {
    return decodedToken.customer?.user_permissions.map(
      (perm: any) => userPermissionsMap.find((funcP: IUserPermission) => funcP.bitPosition === perm)?.name,
    );
  }

  mapFunctionalPermissions(decodedToken: any) {
    return decodedToken.customer?.functional_permissions.map(
      (perm: any) => functionalPermissionsMap.find((funcP: IFunctionalPermissions) => funcP.bit === perm)?.name,
    );
  }

  mapUserDataFromToken(token: AuthToken) {
    return {
      externalStaffReference: token.customer?.external_staff_reference,
      externalCustomerReference: token.customer?.external_customer_reference,
      roles: token.customer?.roles,
    };
  }

  clearUser() {
    // this._userData.next(null);
  }
}
