import { addTelemetryDebug, monitorError, performDraw } from '@datadog/browser-core';
import { isProfilingSupported } from '../domain/profiling/profilingSupported';
import { lazyLoadProfiler } from '../domain/profiling/lazyLoadProfiler';
export function makeProfilerApi() {
  const cleanupTasks = [];
  function stop() {
    cleanupTasks.forEach(task => task());
    cleanupTasks.length = 0;
  }
  function onRumStart(lifeCycle, configuration, sessionManager, viewHistory) {
    // Check if Browser is supporting the JS Self-Profiling API
    if (!isProfilingSupported()) {
      return;
    }
    if (!performDraw(configuration.profilingSampleRate)) {
      // User is not lucky, no profiling!
      return;
    }
    let profiler;
    lazyLoadProfiler().then(createRumProfiler => {
      var _a;
      if (!createRumProfiler) {
        addTelemetryDebug('[DD_RUM] Failed to lazy load the RUM Profiler');
        return;
      }
      profiler = createRumProfiler(configuration, lifeCycle, sessionManager);
      profiler.start((_a = viewHistory.findView()) === null || _a === void 0 ? void 0 : _a.id);
      cleanupTasks.push(() => {
        profiler.stop().catch(monitorError);
      });
    }).catch(monitorError);
  }
  return {
    onRumStart,
    stop
  };
}
