import { Injectable, inject } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../../../environments/environment';
import { IUserAuthorizationLevel } from './auth/user.service';
import { SharedService } from '../../shared/services/shared.service';
import { keyCloakCost } from '@shared/utils/angular-helpers';
import { IStaff } from '@core/interfaces/staff.interface';
import { IAlarm } from '@core/interfaces/alarm.interface';
import { KeycloakTokenParsed } from 'keycloak-js';

interface ExtendedNavigator extends Navigator {
  deviceMemory?: number;
}
const deviceMemory = (navigator as ExtendedNavigator).deviceMemory;
@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  env = environment;
  sharedService = inject(SharedService);

  cpu: number = navigator.hardwareConcurrency;

  hardware = {
    memory: deviceMemory,
    cpu_core: this.cpu,
  };

  SpecificToChargingServicesUserPermissions = [
    'AdministrateDepartureScheduling',
    'ViewDepartureScheduling',
    'AdministratePublicCharging',
    'ViewPublicCharging',
    'ViewChargingDashboard',
    'ViewRemoteClimatization',
    'AdministrateRemoteClimatization',
    'AdministrateChargingSettings',
  ];

  discardUbuntuLogs(): boolean {
    if (navigator.userAgent.indexOf('Ubuntu') != -1) return true;
    return false;
  }

  verifyDatadogLoggingRulesAndLog() {
    if (this.discardUbuntuLogs()) return;

    if (environment.name === 'dev') return;

    this.startLogging();
  }

  sidemenuChanged(sideNavExpanded: boolean) {
    datadogRum.setGlobalContextProperty('sideMenuExpanded', {
      expanded: sideNavExpanded ? true : false,
    });

    datadogRum.addAction('Side Menu Expand Status Changed', {
      category: 'Navigation',
      type: 'User Interaction',
      label: 'Side Menu',
      expanded: sideNavExpanded ? true : false,
    });
  }

  startLogging() {
    // discard site 24x7 sessions, which is ubuntu os

    datadogRum.setGlobalContextProperty('hardware', this.hardware);
    datadogRum.addTiming('Keycloak_INIT', keyCloakCost);

    datadogRum.init({
      applicationId: '8498674a-87b9-4283-8b7e-89d724a37a5b',
      clientToken: 'pub58eb7f06d1d1aa289028ff2113d62c05',
      site: 'datadoghq.eu',
      service: 'charging-services',
      env: this.env.name,
      version: this.env.appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap', 'feature_flags'],
    });

    datadogRum.startSessionReplayRecording();
  }

  endSession() {
    datadogRum.stopSession();
  }

  getChargingServicesRelatedUserPermissions(userpermissions: any[] | undefined) {
    return userpermissions?.filter((up: string) => this.SpecificToChargingServicesUserPermissions.includes(up));
  }

  logUserInfo(parsedToken: KeycloakTokenParsed, userAuthLevel: IUserAuthorizationLevel, staffInfo: IStaff) {
    datadogRum.setUser({
      id: parsedToken['username'],
      roles: parsedToken['customer'].roles,
      name: staffInfo.customerName,
      funcPermissions: userAuthLevel.functionalPermissions,
      userPermissions: this.getChargingServicesRelatedUserPermissions(userAuthLevel.userPermissions),
      external_staff_reference: parsedToken['customer'].external_staff_reference,
      external_customer_reference: parsedToken['customer'].external_customer_reference,
    });
  }

  logAlarms(alarms: IAlarm[]) {
    let isUsingEmail = alarms.some((alarm) => alarm.emailStaffList?.length > 0);
    let isUsingSMS = alarms.some((alarm) => alarm.smsStaffList?.length > 0);
    let isUsingOnchargingFailed: boolean = false;
    let isUsingOnChargingStopped: boolean = false;

    alarms.forEach((alarm) => {
      alarm.alarmTypeList.some((type) => {
        if (type === 'CHARGING_FAILED') isUsingOnchargingFailed = true;
        if (type === 'CHARGING_STOPPED') isUsingOnChargingStopped = true;
        return;
      });
    });

    datadogRum.setGlobalContextProperty('Charging_notifications', {
      sms: isUsingSMS,
      email: isUsingEmail,
      charging_failed: isUsingOnchargingFailed,
      charging_stopped: isUsingOnChargingStopped,
    });
  }
}
