// Keep the following in sync with packages/rum-slim/src/entries/main.ts
import { defineGlobal, getGlobalObject } from '@datadog/browser-core';
import { makeRumPublicApi, startRum } from '@datadog/browser-rum-core';
import { makeRecorderApi } from '../boot/recorderApi';
import { createDeflateEncoder, startDeflateWorker } from '../domain/deflate';
import { lazyLoadRecorder } from '../boot/lazyLoadRecorder';
import { makeProfilerApi } from '../boot/profilerApi';
export { DefaultPrivacyLevel } from '@datadog/browser-core';
const recorderApi = makeRecorderApi(lazyLoadRecorder);
const profilerApi = makeProfilerApi();
export const datadogRum = makeRumPublicApi(startRum, recorderApi, profilerApi, {
  startDeflateWorker,
  createDeflateEncoder
});
defineGlobal(getGlobalObject(), 'DD_RUM', datadogRum);
