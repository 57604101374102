import { computed, Injectable, signal } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
import { IStaff } from '@core/interfaces/staff.interface';
import { initialize } from 'launchdarkly-js-client-sdk';
import { datadogRum } from '@datadog/browser-rum';

export type flagTypes = 'ViewPublicCharging' | 'Admin' | 'Zendesk' | 'ViewMap' | 'DepSchEquipmentSettings';
export const FeatureFlags: Record<flagTypes, string> = {
  ViewPublicCharging: 'enable-chs-ui-viewPublicCharging',
  Admin: 'enable-chs-ui-admin',
  Zendesk: 'enable-chs-ui-zendesk',
  ViewMap: 'enable-chs-ui-viewMap',
  DepSchEquipmentSettings: 'enable-chs-ui-dsEquipSettings',
} as const;

export type FeatureFlagKeys = keyof typeof FeatureFlags;

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private flags = signal<Record<string, boolean>>({});
  flagsReady = signal<boolean>(false);
  enabledFlags = signal<string[]>([]);

  isFlagOn = (key: keyof typeof FeatureFlags) =>
    computed(() => {
      return this.flags()[key] ?? false;
    });

  client: LDClient.LDClient;

  constructor() {}

  private updateFlags(): void {
    const updatedFlags: Record<string, boolean> = {};
    for (const key in FeatureFlags) {
      updatedFlags[key] = this.client?.variation(FeatureFlags[key as keyof typeof FeatureFlags], false) ?? false;
    }
    this.flags.set(updatedFlags);
  }

  initLaunchDarkly(staffInfo: IStaff) {
    const context: LDClient.LDContext = {
      kind: 'multi',
      customer: {
        key: staffInfo.externalCustomerReference,
        name: staffInfo.customerName,
      },
      country: {
        key: staffInfo.country,
        name: staffInfo.country,
      },
    };

    this.client = initialize(environment.launchDarklyKey, context, {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDClient.LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    });

    this.client.on('ready', () => {
      this.updateFlags();
      this.flagsReady.set(true);
      this.setEnabledFlags();
    });
  }

  private setEnabledFlags(): void {
    const allFlags = this.client.allFlags();
    const enabledFlags = Object.keys(allFlags).filter((key) => allFlags[key] === true);
    this.enabledFlags.set(enabledFlags);
  }
}
